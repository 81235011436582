import React, { Component, Fragment } from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import {size, spacing, color} from "../constants"
import { FormattedMessage, Link, useIntl, changeLocale } from "gatsby-plugin-react-intl"

class mobileNav extends Component {
  state = {
    showNav: false,
  }

  openNav = () => {
    this.setState({ showNav: true })
  }

  closeNav = () => {
    this.setState({ showNav: false })
  }

  render() {
    const { showNav } = this.state

    return (
        <>
        <div className="mobile-nav">
            <StyledInput />
            <StyledLabel onClick={this.openNav} css={css`
                width: ${ this.state.showNav ? '75%' : '70px'};
                right: ${ this.state.showNav ? '15em' : '3em'};
                @media(max-width: 680px) {
                    display: ${this.state.showNav ? 'none' : 'flex'};
                }
                `}>
                <Line></Line>
                <Line></Line>
                <Line></Line>
                <Line></Line>
                <Line></Line>
                <Line></Line>
                <Line></Line>
            </StyledLabel>
        </div>   
        <MobileNav visible={showNav}>
            <a href="#" css={css`
                text-transform: lowercase;
                position: absolute;
                top: 1.2em;
                font-size: 2rem !important;
                right: 0;


                @media(max-width: 680px) {
                    margin: auto !important;
                    width: 100%;
                    text-align: center;
                }
                `} 
                onClick={this.closeNav}><FormattedMessage id="zuruck" /></a>

            <Link to="/agenda" activeStyle={{ color: "rgba(255,255,255,0.5)" }}>Agenda</Link>
            <Link to="/aktuell" activeStyle={{ color: "rgba(255,255,255,0.5)" }}><FormattedMessage id="aktuell" /></Link>
            {/* <Link to="/preistraeger-2021" activeStyle={{ color: "rgba(255,255,255,0.5)" }}><FormattedMessage id="preistrager" /> 2021</Link> */}
            <Link to="/preistraeger" activeStyle={{ color: "rgba(255,255,255,0.5)" }}><FormattedMessage id="preistrager" /></Link>
            <Link to="/podcasts" activeStyle={{ color: "rgba(255,255,255,0.5)" }}><FormattedMessage id="podcasts" /></Link>
            <Link to="/archiv" activeStyle={{ color: "rgba(255,255,255,0.5)" }}><FormattedMessage id="archiv" /></Link>
            <Link to="/verein" activeStyle={{ color: "rgba(255,255,255,0.5)" }}><FormattedMessage id="verein" /></Link>
            <Link to="/kontakt" activeStyle={{ color: "rgba(255,255,255,0.5)" }}><FormattedMessage id="kontakt" /></Link>

            <div css={css`
                position: absolute;
                left: 5vw;
                bottom: 5vh;
                /* background-color: red; */
            `}>
                <Link to="/">
                <StaticImage
                    src="../images/orpheus-logo-white.png"
                    width={250}
                    quality={95}
                    placeholder={"tracedSVG"}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Orpheus Music Logo"
                    css={css`
                        margin: auto; 
                        width: 250px;
                        @media(max-width: 680px) {
                            display: none;
                        }
                    `}
                    onClick={this.closeNav}
                    />
                </Link>
                <div>
                    <CustomLink onClick={() => changeLocale('de')}>Deutsch</CustomLink>
                    <CustomLink onClick={() => changeLocale('fr')}>Français</CustomLink>
                    <CustomLink onClick={() => changeLocale('it')}>Italiano</CustomLink>
                    <CustomLink onClick={() => changeLocale('en')}>English</CustomLink>
                    {/* <CustomLink onClick={() => changeLocale('de')}><FormattedMessage id="deutsch" /></CustomLink>
                    <CustomLink onClick={() => changeLocale('fr')}><FormattedMessage id="franzosisch" /></CustomLink>
                    <CustomLink onClick={() => changeLocale('it')}><FormattedMessage id="italienisch" /></CustomLink>
                    <CustomLink onClick={() => changeLocale('en')}><FormattedMessage id="englisch" /></CustomLink> */}
                </div>
            </div>
        </MobileNav>
        </>
    )
  }
}

const StyledLabel = styled.label`
    position: fixed;
    display: flex;
    flex-direction: row;
    background-color: red;
    justify-content: center;
    align-content: center;
    background: #be9e5500; 
    width: 70px;
    height: auto; 
    top: 3em;
    border-radius: 4px; 
    align-self: center;
    transition: all 1s;
    z-index: 2;
    cursor: pointer;
`

const StyledInput = styled.input`
    display: none;
`

const Line = styled.span`
    margin: 4px auto;
    height: 40px; width: 4px; 
    background: #ffffff; border-radius: 1px;
    & :first-of-type {
        margin-top: 13px;
    }
    & :last-of-type {
        margin-bottom: 10px;
    }

`


const MobileNav = styled.div`
    z-index: 1;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    background-color: ${color.COLOR1};
    /* text-transform: uppercase; */
    transition: all 0.25s;

    opacity: ${props => (props.visible ? '1' : '0')};
    visibility: ${props => (props.visible ? 'visible' : 'hidden')};
    
    & > a {
        font-family: 'Playfair Display';
        color: ${color.WHITE};
        font-size: 2.5em;
        margin: 0.5em 3em 0.5em 0;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
        text-decoration: none;
        transition: color 1s;
        &:hover {
            transition: color 0.25s;
            color: rgba(255,255,255,0.5);
        }

        @media(max-width: 980px) {
            margin: 0.5em 1em 0.5em 0;
        }
        @media(max-width: 680px), (max-height: 680px){
            font-size: 1.5em;
        }
        @media(max-width: 680px){
            margin-right: 0;
        }
    }

    @media(max-width: 680px){
        align-items: center;
    }
`

const CustomLink = styled.span`
  display: inline-block;
  margin: 2em 2em 0 0;
  padding: 0.5em 1em;
  background-color: ${color.COLOR2};
  color: white;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    background-color: ${color.COLOR2INTENSE};
    -webkit-transition: background-color 0.25s linear;
    -ms-transition: background-color 0.25s linear;
    transition: background-color 0.25s linear;
  }

    @media(max-width: 680px), (max-height: 680px){
        margin: 0.5em 0.5em 0 0;
    }
`


export default mobileNav