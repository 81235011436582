export const spacing = {
  BASE: "1em",
  DOUBLE: "2em",
  xs: '10px',
  small: '20px',
  medium: '40px',
  big: '80px'
}

export const color = {
  COLOR1: "#443F3F",
  COLOR2: "rgba(179,136,101,0.64)",
  COLOR2INTENSE: "rgb(179,136,101)",
  COLOR2REDUCED: "rgba(179,136,101,0.69)",
  BGTRANSPARENT: "rgba(24, 28, 32, 0.75)",
  BGDARK: "#0f1214",
  BGLIGHT: "#2c2d2d",
  WHITE: "#ffffff"
}

export const size = {
  MAXWIDTH: "1260px",
}

export const screen = {
  xsPhone: '440px',
  smallTablet: '680px',
  tablet: '980px',
  laptop: '1280px',
  desktop: '1920px',
}

export const ensembles = [
  {
    "name": "Dora Piano Trio",
    "url": "https://www.instagram.com/dorapianotrio/",
    "jahr": 2024
  },
  {
    "name": "Sax & Perc Duo",
    "url": "https://www.instagram.com/saxpercduo",
    "jahr": 2024
  },
  {
    "name": "Momo String Quartet",
    "url": "",
    "jahr": 2024
  },
  {
    "name": "Wendel Quartet",
    "url": "https://www.instagram.com/wendel_quartet/",
    "jahr": 2024
  },
  {
    "name": "Arda String Quartet",
    "url": "https://www.instagram.com/ardaquartet/",
    "jahr": 2024
  },
  {
    "name": "Trio Basilea",
    "url": "",
    "jahr": 2023
  },
  {
    "name": "Trio Zarathoustra",
    "url": "https://www.facebook.com/triozarathoustra/",
    "jahr": 2023
  },
  {
    "name": "Trio Archai",
    "url": "",
    "jahr": 2023
  },
  {
    "name": "Saxophone & Percussion Duo",
    "url": "",
    "jahr": 2023
  },
  {
    "name": "Nemesis Quartet",
    "url": "https://www.facebook.com/p/Nemesis-Quartet-100063623166742",
    "jahr": 2023
  },
  {
    "name": "Spirea Quartet",
    "url": "https://www.facebook.com/profile.php?id=100087890358686",
    "jahr": 2022
  },
  {
    "name": "Trio Chagall",
    "url": "http://www.triochagall.com/en/",
    "jahr": 2022
  },
  {
    "name": "Trio Zeitgeist",
    "url": "",
    "jahr": 2022
  },
  {
    "name": "Helix Trio",
    "url": "https://www.instagram.com/helixpianotrio/",
    "jahr": 2022
  },
  {
    "name": "Quatuor Amapola",
    "url": "https://www.instagram.com/quatuoramapola/",
    "jahr": 2022
  },
  {
    "name": "Opus Five Quintet",
    "url": "",
    "jahr": 2022
  },
  {
    "name": "Atenea Quartet",
    "url": "https://www.ateneaquartet.com",
    "jahr": 2021
  },
  {
    "name": "Modulor Quartet",
    "url": "https://www.modulorquartet.ch/",
    "jahr": 2021
  },
  {
    "name": "Moser String Quartet",
    "url": "https://www.moserstringquartet.com",
    "jahr": 2021
  },
  {
    "name": "Davidoff Trio",
    "url": "https://www.davidofftrio.de/",
    "jahr": 2021
  },
  {
    "name": "Trio Vanitas",
    "url": "",
    "jahr": 2021
  },
  {
    "name": "Sonoris Piano Trio",
    "url": "https://dumpor.com/v/sonorispianotrio",
    "jahr": 2021
  },
  {
    "name": "Quartetto Eos",
    "url": "https://www.quartettoeos.com",
    "jahr": 2020
  },
  {
    "name": "KamBrass Quintet",
    "url": "https://kambrassquintet.com/de/",
    "jahr": 2020
  },
  {
    "name": "Nanos Trio",
    "url": "",
    "jahr": 2020
  },
  {
    "name": "Camerata Rhein",
    "url": "https://www.facebook.com/cameratarhein/",
    "jahr": 2020
  },
  {
    "name": "Trio 99",
    "url": "",
    "jahr": 2020
  },

  {
    "name": "Nerida Quartett",
    "url": "https://www.neridaquartett.net/",
    "jahr": 2019
  },
  {
    "name": "Duo Sikrona",
    "url": "",
    "jahr": 2019
  },
  {
    "name": "Atreus Trio",
    "url": "",
    "jahr": 2019
  },
  {
    "name": "Berchtold Piano Trio",
    "url": "https://anielesteininger.com/?page_id=27",
    "jahr": 2019
  },
  {
    "name": "Basilea Wind Quintet",
    "url": "https://www.basileawindquintet.com/",
    "jahr": 2019
  },
  {
    "name": "Trio Ernest",
    "url": "https://www.ernestpianotrio.com/"
    ,"jahr": 2019
  },

  {
    "name": "Kebyart Ensemble",
    "url": "https://www.kebyart.com/",
    "jahr": 2018
  },
  {
    "name": "Percussion CYTi",
    "url": "",
    "jahr": 2018
  },
  {
    "name": "Opalio Trio",
    "url": "https://www.chiaraopalio.com/opalio-quintet",
    "jahr": 2018
  },
  {
    "name": "Alean Duo",
    "url": "",
    "jahr": 2018
  },
  {
    "name": "Duo Valmore",
    "url": "",
    "jahr": 2018
  },
  {
    "name": "Duo Minten-Alsina",
    "url": "",
    "jahr": 2018
  },
]

export const composers = [
  {
    "name": "Marie-Florence Burki",
    "url": "https://www.instagram.com/mariefloburki/",
    "jahr": 2024
  },
  {
    "name": "Luca Staffelbach",
    "url": "https://www.instagram.com/lucastbach/",
    "jahr": 2024
  },
  {
    "name": "Ludmilla Mercier",
    "url": "https://mercierludmilla.com/",
    "jahr": 2024
  },
  {
    "name": "Asia Ahmetjanova",
    "url": "https://ahmetjanova.com",
    "jahr": 2023
  },
  {
    "name": "Dominic Röthlisberger",
    "url": "https://www.dominicroethlisberger.ch",
    "jahr": 2023
  },
  {
    "name": "Marylène Müller",
    "url": "http://marylenemueller.ch",
    "jahr": 2023
  },
  {
    "name": "Gaudenz Wigger",
    "url": "https://www.musinfo.ch/de/personen/?pers_id=1678&abc=W",
    "jahr": 2022
  },
  {
    "name": "Rahel Zimmermann",
    "url": "https://www.rahelzimmermann.com/",
    "jahr": 2022
  },
  {
    "name": "Jean-Sélim Abdelmoula",
    "url": "https://www.abdelmoula.com/",
    "jahr": 2022
  },
  {
    "name": "Mauro Hertig",
    "url": "https://maurohertig.com/",
    "jahr": 2021
  },
  {
    "name": "Patricia Meier",
    "url": "https://www.patricia-meier.ch/",
    "jahr": 2021
  },
  {
    "name": "Verena Weinmann",
    "url": "https://verenaweinmann.wordpress.com/",
    "jahr": 2021
  },
  {
    "name": "Alsu Nigmatulina",
    "url": "",
    "jahr": 2020
  },
  {
    "name": "Maximilian Whitcher",
    "url": "https://www.maximilianwhitcher.com",
    "jahr": 2020
  },
  {
    "name": "Keitaro Takahashi",
    "url": "https://www.babelscores.com/KeitaroTakahashi",
    "jahr": 2020
  },
  {
    "name": "Lukas Stamm",
    "url": "https://lukasstamm.com/",
    "jahr": 2019
  },
  {
    "name": "Silvan Loher",
    "url": "https://www.silvanloher.com/",
    "jahr": 2019
  },
  {
    "name": "Nathanael Gubler",
    "url": "https://www.ngubler.com/",
    "jahr": 2019
  },
  {
    "name": "Nemanja Radivojevic",
    "url": "http://nemanjaradivojevic.com/",
    "jahr": 2018
  },
  {
    "name": "Aram Hovannisyan",
    "url": "https://www.facebook.com/104684629604598/posts/aram-hovhannisyan-winner-of-fluchtwege-composers-competition-in-bern-switzerland/195318800536270/",
    "jahr": 2018
  },
  {
    "name": "Léo Albisetti",
    "url": "https://www.facebook.com/SwissChamberMusicFestival/photos/a.2195865220523151/2195865287189811/?type=3&eid=ARDts4fkqRcg-6yW_BSlH-FCiz9rqNAADK4dV3-IlZyLyHB1jppEw1_b2ZwybB5yIkgX8eDynsKAGZvI",
    "jahr": 2018
  }
]